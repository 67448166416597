<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="공정사고 기본정보" class="cardClassDetailInfo">
          <template slot="card-detail">
            <div class="col-3">
              <c-label-text title="사고번호" :value="standardInfo.accidentNo"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="사고명" :value="standardInfo.accidentName"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="사고종류" :value="standardInfo.accidentKindName"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="사고등급" :value="standardInfo.accidentGradeName"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="발생일시" :value="standardInfo.occurrenceDate"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="발생부서" :value="standardInfo.occurrenceDeptName"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="발생형태 대분류" :value="standardInfo.occurrenceModeLargeName"></c-label-text>
            </div>
            <div class="col-3">
              <c-label-text title="발생형태 중분류" :value="standardInfo.occurrenceModeSmallName"></c-label-text>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="" title="공정사고보고서 정보" height="min-height" :noMarginPadding="true">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn :label="'['+$language(standardInfo.reportName) +'] '+ $language('출력')" icon="print" @btnClicked="accidentPrint1" />
            </q-btn-group>
          </template>
        </c-card>
        <q-list bordered>
          <q-separator spaced inset />
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <q-item>
                <q-item-section>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-black text-uppercase">{{$language('1. 일반사항')}}</q-item-label>
                  <br>
                  <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "사업장 : " }} {{ standardInfo.plantName }}</q-item-label>
                  <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "사고발생일시 : " }} {{ standardInfo.occurrenceDate }}</q-item-label>
                  <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "사고발생장소 : " }} {{ standardInfo.occurrenceLocation }}</q-item-label>
                  <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "사고조사기간 : " }} {{ standardInfo.investigationDate }}</q-item-label>
                </q-item-section>
                <!-- <q-item-section avatar>
                  <q-avatar text-color="yellow" icon="star" />
                </q-item-section> -->
              </q-item>

              <q-separator spaced inset />

              <q-item>
                <q-item-section>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-black text-uppercase">{{$language('2. 공정사고의 형태')}}</q-item-label>
                  <q-item-label caption lines="2">
                      <!-- :disabled="true" -->
                    <c-multi-select
                      codeGroupCd="IIM_KIND_CD"
                      :editable="editable"
                      :isArray="false"
                      :disabled="true"
                      itemText="codeName"
                      itemValue="code"
                      maxValues="3"
                      label=""
                      name="accidentKindCd"
                      v-model="standardInfo.accidentKindCd"
                    >
                    </c-multi-select>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced inset />

              <q-item>
                <q-item-section>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-black text-uppercase">{{$language('3. 공정사고의 상황개요')}}</q-item-label>
                  <br>
                  <q-item-label v-for="(item, index) in overviewAccidentSituations"
                    :key="index"
                    class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{item}} 
                  </q-item-label>
                </q-item-section>
              
              </q-item>

              <q-separator spaced inset />

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

              <q-item>
                <q-item-section>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-black text-uppercase">{{$language('4. 공정사고의 발생원인')}}</q-item-label>
                  <br>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "직접원인 : " }} {{ standardInfo.directCause }}</q-item-label>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "간접원인 : " }} {{ standardInfo.indirectCause }}</q-item-label>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "기인물 : " }} {{ standardInfo.openning }}</q-item-label>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "가해물 : " }} {{ standardInfo.injurious }}</q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced inset />
              
              <q-item>
                <q-item-section>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-black text-uppercase">{{$language('5. 사고피해')}}</q-item-label>
                  <br>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "물적피해내역 : " }} {{ standardInfo.materialDamageHistory }}</q-item-label>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "인적피해내역 : " }} {{ standardInfo.humanDamageHistory }}</q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced inset />

              <q-item>
                <q-item-section>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-black text-uppercase">{{$language('6. 긴급조치사항')}}</q-item-label>
                  <br>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp; {{ standardInfo.emergencyMeasures }}</q-item-label>

                </q-item-section>
              </q-item>
              
              <q-separator spaced inset />

              <q-item>
                <q-item-section>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-black text-uppercase">{{$language('7. 공정사고 재발장지를 위한 장·단기 대책')}}</q-item-label>
                  <br>
                  <q-item-label caption lines="3" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">
                    <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ "단기 대책 : " }} {{ standardInfo.shortPlan }}</span>
                  </q-item-label>
                  <q-item-label caption lines="3" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">
                    <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ "중장기 대책 : " }} {{ standardInfo.longPlan }}</span>
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced inset />

              <q-item>
                <q-item-section>
                  <q-item-label class="q-mt-xs text-body2 text-weight-bold text-black text-uppercase">{{$language('8. 담당자 의견')}}</q-item-label>
                  <br>
                  <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">
                    <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ standardInfo.managerOpinion }}</span>
                    </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator spaced inset />
            </div>
          </div>

        </q-list>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-accident-report',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      standardInfo: {
        accidentNo: '',
        accidentName: '',
        accidentKindCd: '',
        accidentKindName: '',
        accidentGradeName: '',
        occurrenceDate: '',
        occurrenceDeptName: '',
        occurrenceModeLargeName: '',
        occurrenceModeSmallName: '',
        accidentStatusCd: '',
        occurrenceLocation: '',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
      },
      overviewAccidentSituations: [],
      searchUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.report.get.url;
      this.printUrl = selectConfig.sop.iim.accident.report.print.url;
      // code setting
      // list setting
      this.getStdInfo();
    },
    getStdInfo() {
      if (this.param.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.standardInfo = _result.data;

          if (this.standardInfo.overviewAccidentSituation) {
            this.overviewAccidentSituations = this.standardInfo.overviewAccidentSituation.split('\n')
          }
          this.updateMode = true;
        },);
      }
    },
    accidentPrint1() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.param.iimAccidentId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '['+this.standardInfo.reportName +'] '+ this.standardInfo.accidentName + '.docx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    btnRecord() {
      this.popupOptions.target = () =>
      import(`${"@/pages/mdm/fim/equipmentPreview.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.param = this.selectedRow;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
